import React from "react";
import { Link } from "gatsby";
import SEO from "../components/Seo";
import Layout from "../components/Layout";
import SpacingVertical from "../components/SpacingVertical";
import SpacingHorizontal from "../components/SpacingHorizontal";
import CallToAction from "../components/CallToAction";
import Colors from "../constants/Colors";
import Strings from "../constants/Strings";
import Endpoint from "../constants/Endpoint";
import LinkArrow from "../components/LinkArrow";
import ObjectDetectorImage from "../images/object-detector-demo.jpg";
import ObjectDetectorImageAerial from "../images/object-detector-aerial-demo.jpg";
import ImageClassifierImage from "../images/image-classifier-demo.jpg";

const Demos = () => {
  return (
    <Layout override="marginStandard">
      <SpacingVertical rems={4} />
      <SEO title={Strings.Models.Home.title} />
      <h1>{Strings.Models.Home.title}</h1>
      <p style={styles.textDescription}>{Strings.Models.Home.description}</p>
      <ul>
        <li>{Strings.Models.Home.callout1}</li>
        <li>{Strings.Models.Home.callout2}</li>
      </ul>
      <SpacingVertical />
      <div style={styles.container}>
        <div style={styles.image}>
          <Link to={Endpoint.imageClassification}>
            <img src={ImageClassifierImage} />
          </Link>
          <h3>{Strings.Models.ImageClassification.title}</h3>
          <p>{Strings.Models.ImageClassification.subtitle}</p>
          <LinkArrow to={Endpoint.imageClassification}>
            {Strings.Models.tryThis}
          </LinkArrow>
        </div>
        <SpacingHorizontal rems={2} />
        <div style={styles.image}>
          <SpacingVertical className="hideDesktop" rems={2} />
          <Link to={Endpoint.imageObjectDetection}>
            <img src={ObjectDetectorImage} />
          </Link>
          <h3>{Strings.Models.ImageObjectDetection.title}</h3>
          <p>{Strings.Models.ImageObjectDetection.subtitle}</p>
          <LinkArrow to={Endpoint.imageObjectDetection}>
            {Strings.Models.tryThis}
          </LinkArrow>
        </div>
        <SpacingHorizontal rems={2} />
        <div style={styles.image}>
          <SpacingVertical className="hideDesktop" rems={2} />
          <Link to={Endpoint.imageObjectDetectionAerial}>
            <img src={ObjectDetectorImageAerial} />
          </Link>
          <h3>{Strings.Models.ImageObjectDetectionAerial.title}</h3>
          <p>{Strings.Models.ImageObjectDetectionAerial.subtitle}</p>
          <LinkArrow to={Endpoint.imageObjectDetectionAerial}>
            {Strings.Models.tryThis}
          </LinkArrow>
        </div>
      </div>
      <SpacingVertical rems={4} />
      <CallToAction
        backgroundColor={Colors.grayLight}
        inputColor={Colors.white}
        labelColor={Colors.blueDark}
      />
      <SpacingVertical rems={2} />
    </Layout>
  );
};

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "flex-start",
  } as const,
  image: {
    maxWidth: "400px",
    marginBottom: "2rem",
  },
  textDescription: {
    maxWidth: "700px",
  },
};

export default Demos;
